import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import IMOButton from "@components/common/IMOButton";

const PortalsExport = () => {
  return (
    <section
      className={`w-screen -ml-4 sm:ml-0 sm:w-full bg-soft-grey sm:rounded-[32px] my-10 lg:my-[86px]`}
    >
      <div className="flex justify-between items-center flex-wrap-reverse lg:flex-nowrap py-[40px] px-[16px] lg:py-[86px] lg:px-[104px] xl:py-[40px]">
        <div className="flex flex-col lg:w-[400px] gap-base">
          <h2>Eksport do portali ogłoszeniowych</h2>
          <p className="text-grey text-base">
            IMO oferuje eksport zarówno do płatnych jak i bezpłatnych portali
            ogłoszeniowych
          </p>
          <Link to="/program-crm/eksport-do-portali/">
            <IMOButton variant="secondary">Czytaj więcej</IMOButton>
          </Link>
        </div>

        <div className="relative w-full lg:w-1/2">
          <StaticImage
            src={"../../assets/images/logos.png"}
            alt="Loga portali współpracujących z IMO"
          />
        </div>
      </div>
    </section>
  );
};

export default PortalsExport;
