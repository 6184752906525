import React, { useEffect, useRef, useState } from "react";
import Arrow from "@assets/images/arrow-top.svg";
import PropTypes from "prop-types";

let timeout;

export const CollapseControlled = ({ open, children }) => {
  const [height, setHeight] = useState(null);
  const contentElement = useRef(null);

  const openHandle = (open) => {
    if (open) {
      setHeight(contentElement.current.scrollHeight);
      timeout = setTimeout(() => setHeight("unset"), 150);
    } else if (!open && height === "unset") {
      setHeight(contentElement.current.scrollHeight);
      timeout = setTimeout(() => setHeight(0), 50);
    } else {
      clearTimeout(timeout);
      setHeight(0);
    }
  };
  useEffect(() => {
    openHandle(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <div
      ref={contentElement}
      style={{ height: height || 0 }}
      className="overflow-hidden transition-all duration-200"
    >
      {children}
    </div>
  );
};

const Collapse = ({ title, noContainer, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const contentElement = useRef(null);

  const openHandle = () => {
    setIsOpen((prev) => !prev);
    setHeight(!isOpen ? contentElement.current.scrollHeight : 0);
  };
  return (
    <div
      itemscope
      itemprop="mainEntity"
      itemtype="https://schema.org/Question"
      className="bg-off-blue rounded-2xl"
    >
      <button
        onClick={openHandle}
        className={"w-full flex items-center p-4 text-blue cursor-pointer"}
      >
        <Arrow
          className={`min-w-[18px] min-h-[10px] mr-[10px] transition ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        />
        <h4
          itemprop="name"
          className={`text-lg w-full text-start ${isOpen ? "text-black" : ""}`}
        >
          {title}
        </h4>
      </button>
      <div
        itemscope
        itemprop="acceptedAnswer"
        itemtype="https://schema.org/Answer"
        ref={contentElement}
        style={{ height: height }}
        className="overflow-hidden transition-all duration-200"
      >
        {noContainer ? (
          children
        ) : (
          <p
            itemprop="text"
            className="p-4 text-grey text-base ml-8 text-start"
          >
            {children}
          </p>
        )}
      </div>
    </div>
  );
};

Collapse.propTypes = {
  title: PropTypes.string,
  noContainer: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};
export default Collapse;
