const React = require("react");
const Layout = require("./src/components/layout/index.js").default;
require("./src/styles/global.css");

exports.wrapPageElement = ({ element, props }) => {
  return <Layout path={props.path}>{element}</Layout>;
};
exports.onRouteUpdate = ({ location }) => {
  if (location && location.hash) {
    setTimeout(() => {
      const item = document.querySelector(`${location.hash}`).offsetTop;
      window.scrollTo({
        top: item,
        behavior: "smooth",
      });
    });
  }

  return true;
};
