import React, { useEffect, useState } from 'react'
import BirthdayPromotion from './BirthdayPromotion'

const setCookie = (days = 1) => {
    const d = new Date()
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000)
    const expires = 'expires=' + d.toUTCString()
    document.cookie = 'modal=true;' + expires + ';path=/'
}

const ModalPromotion = () => {
    const [modal, setModal] = useState(false)
    const [cookieModal, setCookieModal] = useState(true)

    useEffect(() => {
        const name = 'modal='
        const decodedCookie = decodeURIComponent(document.cookie)
        const ca = decodedCookie.split(';')
        let val

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                val = c.substring(name.length, c.length)
            }
        }
        setCookieModal(val === 'true')

        document.body.addEventListener('mouseleave', function (event) {
            if (
                event.clientY <= 0 ||
                event.clientX <= 0 ||
                event.clientX >= window.innerWidth ||
                event.clientY >= window.innerHeight
            ) {
                setModal(true)
            }
        })
    }, [])

    return (
        <>
            {!cookieModal && modal && (
                <>
                    <div
                        id="popupmodal"
                        style={{ maxWidth: 'fit-content', height: 'fit-content' }}
                        className="fixed top-0 left-0 right-0 z-50 p-4 m-auto overflow-x-hidden overflow-y-auto md:inset-0 max-h-full justify-center items-center flex"
                    >
                        <div className="relative w-full max-w-[800px] max-h-full">
                            <div className="relative bg-soft-grey rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setModal(false)
                                        setCookie(1)
                                        setCookieModal(true)
                                    }}
                                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-gray"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="text-center">
                                    <BirthdayPromotion />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{ background: 'black', opacity: '0.7' }}
                        aria-hidden="true"
                        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
                        onClick={() => {
                            setModal(false)
                            setCookie(1)
                            setCookieModal(true)
                        }}
                    ></div>
                </>
            )}
        </>
    )
}

export default ModalPromotion
