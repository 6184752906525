import React from "react";

export const faqRWD = [
  {
    title:
      "Z jakimi programami zintegrowane są wasze strony www dla biur nieruchomości?",
    description: (
      <>
        Strony www dla biur nieruchomości z oferty IMO są zintegrowane z
        najpopularniejszymi systemami CRM, jak:{" "}
        <ul className="faq-list">
          <li>Asari</li>
          <li>Galactica VIRGO</li>
          <li>Agencja 5000</li>
          <li>EstiCRM</li>
          <li>REN24</li>
          <li>mediaRent</li>
          <li>szybkoPLUS</li>
        </ul>
        Oprócz tego mają możliwość zarządzania wszelkimi odnośnikami do profili
        w mediach społecznościowych (Facebook, Skype, YouTube, X (Twitter),
        Instagram, LinkedIn, TikTok).
      </>
    ),
  },
  {
    title: "Jaka jest opłata za utrzymanie strony www dla biur nieruchomości?",
    description: (
      <>
        W przypadku strony ekspresowej obowiązuje stała opłata miesięczna w
        wysokości 99 zł + VAT. Dla płatności z góry za rok oferujemy 30% rabatu
        a za dwa lata 40% rabatu. Nie ma żadnych dodatkowych opłat za hosting,
        takich jak utrzymanie strony WWW na serwerze. W cenie są kopie
        bezpieczeństwa i zapewnienie ciągłości działania strony. Przez cały
        okres korzystania ze strony www dla biura nieruchomości zlecanie zmian
        poprzez system CMS jest w cenie!
      </>
    ),
  },
  {
    title:
      "Jakie są najważniejsze funkcje dobrej strony dla biur nieruchomości?",
    description: (
      <>
        Dobra strona dla biura nieruchomości powinna działać bezobsługowo dzięki
        dodawaniu ofert automatycznie za pośrednictwem integracji z dowolnym
        programem. Ponadto niezbędną funkcją jest możliwość samodzielnego
        zarządzania treścią za pomocą panelu CMS. Z kolei poszukujący powinien
        mieć możliwość łatwego filtrowania nieruchomości według
        najpopularniejszych kryteriów spotykanych w portalach ogłoszeniowych a
        prezentacja oferty powinna być przejrzysta.
      </>
    ),
  },
  {
    title: "Na co zwrócić uwagę przy wyborze strony dla biur nieruchomości?",
    description: (
      <>
        Warto zwrócić uwagę na poprawną responsywność na każdym urządzeniu,
        łatwość nawigacji w celu odnalezienia ofert spełniających wymagania
        klienta oraz poziom optymalizacji kodu źródłowego pod kątem naturalnego
        pozycjonowania w wyszukiwarce Google. Strona powinna mieć gwarancję
        przez cały okres korzystania z usługi, hosting w cenie, możliwość
        samodzielnej edycji treści za pomocą panelu CMS, a także integrację z
        popularnymi programami.
      </>
    ),
  },
  {
    title:
      "Czym cechują się profesjonalne strony internetowe dla biur nieruchomości?",
    description: (
      <>
        Profesjonalne strony internetowe dla biur nieruchomości od IMO są przede
        wszystkim dostosowane do aktualnych potrzeb rynku. Zapewniają one{" "}
        <strong>
          intuicyjną nawigację, responsywne projekty graficzne, które gwarantują
          doskonałą prezentację ofert na różnych urządzeniach, oraz funkcje
          wyszukiwania
        </strong>{" "}
        umożliwiające klientom łatwe znalezienie interesujących ich
        nieruchomości.
      </>
    ),
  },
  {
    title:
      "Jak strona internetowa dla biura nieruchomości może mi pomóc w prowadzeniu biznesu?",
    description: (
      <>
        Strona internetowa dla biura nieruchomości od IMO umożliwia zwiększenie
        widoczności w sieci, budowanie zaufania i wizerunku profesjonalnego
        biura. Automatyczne dodawanie ofert pozwala zaoszczędzić czas, który
        można poświęcić na prowadzenie działań marketingowych np. wykorzystując
        reklamę strony dla biura nieruchomości w wyszukiwarce Google. Wszystko
        to przekłada się na większą liczbę klientów, szybsze transakcje i
        wzmocnienie marki firmy.
      </>
    ),
  },
];

export const faqDeweloper = [
  {
    title: "Czym się kierować przy wyborze strony internetowej dla dewelopera?",
    description: (
      <>
        Wybierając stronę internetową dla dewelopera, należy{" "}
        <strong>
          zwrócić uwagę na jej funkcjonalność, czytelność dla potencjalnych
          odbiorców oraz możliwość łatwego zarządzania kryteriami wyszukiwania,
          takimi jak cena, piętro czy plan lokalu
        </strong>
        . Zdecydowanie ułatwi to wymianę informacji i przyspieszy proces
        transakcji: klient - deweloper. Strona wykonana przez IMO może zawierać
        również interaktywny plan inwestycji z uwzględnieniem poszczególnych
        elementów budynku.
      </>
    ),
  },
  {
    title: "Jakie funkcje powinna mieć strona internetowa dla dewelopera?",
    description: (
      <>
        Strona internetowa dla dewelopera powinna zawierać{" "}
        <strong>
          szczegółowy opis inwestycji z interaktywnym planem, galerię zdjęć z
          dziennika budowy, wyszukiwarkę nieruchomości z filtrami, mapę z
          lokalizacją oraz formularz kontaktowy
        </strong>
        . Istotna jest również integracja z programem IMO i ewentualnymi
        narzędziami, które ułatwią zarządzanie procesem sprzedaży, np. chatem.
      </>
    ),
  },
  {
    title:
      "Jakie są najważniejsze funkcje dobrej strony dla biur nieruchomości?",
    description: (
      <>
        Dobra strona dla biura nieruchomości powinna działać bezobsługowo dzięki
        dodawaniu ofert automatycznie za pośrednictwem integracji z dowolnym
        programem. Ponadto niezbędną funkcją jest możliwość samodzielnego
        zarządzania treścią za pomocą panelu CMS. Z kolei poszukujący powinien
        mieć możliwość łatwego filtrowania nieruchomości według
        najpopularniejszych kryteriów spotykanych w portalach ogłoszeniowych a
        prezentacja oferty powinna być przejrzysta.
      </>
    ),
  },
  {
    title: "Ile czasu zajmuje utworzenie strony dla dewelopera?",
    description: (
      <>
        Czas potrzebny na stworzenie strony internetowej dla dewelopera zależy
        od skomplikowania projektu i liczby funkcji, ale zazwyczaj proces ten
        trwa miesiąc. Klient, który zdecyduje się na usługę zaprojektowania
        strony przez IMO, otrzyma również możliwość skorzystania z programu IMO
        umożliwiającego eksport do portali ogłoszeniowych.
      </>
    ),
  },
  {
    title: "Ile kosztuje stworzenie dobrej strony dla dewelopera?",
    description: (
      <>
        Koszt stworzenia strony internetowej dla dewelopera zależy od wielu
        czynników, takich jak złożoność projektu wizualnego, funkcjonalności i
        dodatkowych narzędzi.{" "}
        <strong>
          Cena za stronę szablonową z interaktywnym planem inwestycji to koszt
          od 5000 zł + VAT. Cena nie uwzględnia przygotowania materiałów
          graficznych takich jak widok planu inwestycji, kondygnacji albo kart
          lokali.
        </strong>
      </>
    ),
  },
  {
    title: "Jakie są cechy strony www dla deweloperów?",
    description: (
      <>
        Dobra strona www dla deweloperów powinna być użyteczna i przyjazna dla
        użytkowników oraz responsywna, co umożliwia wygodne korzystanie z niej
        na różnych urządzeniach. Nie może zabraknąć również takich elementów
        jak:
        <ul className="faq-list">
          <li>wysoka jakość zdjęć i filmów,</li>
          <li>interaktywny plan inwestycji, </li>
          <li>sortowanie według kryteriów, </li>
          <li>podstawowa wyszukiwarka, </li>
          <li>informacje o firmie i referencje,</li>
          <li>dziennik budowy (galeria zdjęć),</li>
          <li>integracja z programem IMO.</li>
        </ul>
      </>
    ),
  },
  {
    title: "Jak wygląda proces personalizacji strony www dla deweloperów?",
    description: (
      <>
        Proces personalizacji strony internetowej dla deweloperów{" "}
        <strong>
          w IMO zaczyna się od przeprowadzenia konsultacji z klientem, a
          następnie przygotowywany jest projekt graficzny
        </strong>{" "}
        z użyciem responsywnego szablonu. W cenie przewidziane są dwie tury
        poprawek projektu graficznego. Wszelkie zmiany związane z kolorystyką,
        ikonografikami, przyciski menu strony są w cenie. Po akceptacji projektu
        klient otrzymuje stronę www dostosowaną do swoich potrzeb.
      </>
    ),
  },
];

export const faqCRM = [
  {
    title:
      "Jakie są zalety z korzystania z oprogramowania dla agencji nieruchomości?",
    description: (
      <>
        <strong>
          Program dla biur nieruchomości IMO to intuicyjne oprogramowanie CRM
        </strong>
        , które umożliwia efektywne zarządzanie bazą nieruchomości i kontaktami
        do klientów, a także publikację ofert w kilkudziesięciu portalach
        ogłoszeniowych i na stronie internetowej, wspieranie procesów
        sprzedażowych i dostęp przez przeglądarkę z dowolnego urządzenia.
      </>
    ),
  },
  {
    title:
      "Jakie funkcje powinno mieć dobre oprogramowanie dla biur nieruchomości?",
    description: (
      <>
        Wybierając program dla biur nieruchomości, warto zdecydować się na ten,
        który będzie przystosowany do bieżących potrzeb firmy oraz skali
        działalności. Najlepsze oprogramowanie dla pośredników nieruchomości to
        takie, które przede wszystkim umożliwia:
        <ul className="faq-list">
          <li>szybkie wprowadzanie ofert,</li>
          <li>
            kalendarz spotkań powiązany z ofertami i klientami poszukującymi,
          </li>
          <li>automatyczny eksport ofert do portali ogłoszeniowych,</li>
          <li>
            sprawne zarządzanie biurem (fakturowanie, zarządzanie dokumentami i
            działaniami, wysyłanie ofert do klientów).
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "Jaki jest koszt dobrego systemu dla biur nieruchomości?",
    description: (
      <>
        Koszt programu dla biur nieruchomości może się różnić w zależności od
        liczby funkcji, liczby użytkowników i poziomu wsparcia technicznego.
        Ceny za oprogramowanie IMO zaczynają się już od 55 zł + VAT miesięcznie
        i są zależne tylko od liczby użytkowników oraz długości okresu
        rozliczeniowego. Wszystkie funkcje są zawsze w cenie!
      </>
    ),
  },
  {
    title:
      "Czym wyróżnia się IMO na tle konkurencyjnych programów do obsługi biur nieruchomości?",
    description: (
      <>
        Program dla nieruchomości IMO jest tańszy od innych wiodących programów
        na rynku, nie ma żadnych limitów ani opłat startowych czy
        instalacyjnych, za eksporty do portali oraz stronę WWW.
        <br />
        <br />
        Wszystkie funkcje IMO oraz aktualizacje są zawsze w cenie.
      </>
    ),
  },
  {
    title: "Czy IMO to sprawdzony program dla pośredników nieruchomości?",
    description: (
      <>
        IMO jest rozwijany przez ponad 16 lat i został sprawdzony przez grono
        ponad 4400 pośredników nieruchomości z 1830 biur. Dbamy o wzorowe i
        błyskawiczne wsparcie klienta. Doradzamy i pomagamy, dlatego co szóste
        biuro wybrało IMO z polecenia.
      </>
    ),
  },
  {
    title: "Jaki CRM dla biura nieruchomości będzie dla mnie odpowiedni?",
    description: (
      <>
        Program dla biura nieruchomości IMO to idealny wybór dla klientów,
        którzy chcą rozpocząć pracę z bazą ofert i klientów bez potrzeby
        szkolenia i wielogodzinnego wdrażania się w oprogramowanie.
        <br />
        <br />
        Dzięki kompleksowej ofercie składającej się z programu dla biura
        nieruchomości, zintegrowanej strony internetowej, domeny oraz poczty
        E-mail, IMO może być jedynym usługodawcą najważniejszych narzędzi
        potrzebnych do prowadzenia biura nieruchomości.
      </>
    ),
  },
  {
    title: "Jak w rankingu programów dla biur nieruchomości wypada IMO?",
    description: (
      <>
        Oprogramowanie IMO dla biur nieruchomości jest wysoko cenione w branży
        za swoją niezawodność, łatwość obsługi oraz wsparcie klienta. Program
        jest często rekomendowany jako jedno z najlepszych rozwiązań dla
        przedsiębiorstw zajmujących się nieruchomościami. IMO doskonale sprawdza
        się w firmach, które szukają kompleksowego oprogramowania CRM.
      </>
    ),
  },
];
