import { Link } from "gatsby";
import React from "react";
import IMOButton from "@components/common/IMOButton";

const ProgramPromo = () => {
  return (
    <>
      <p class="w-full text-center text-xs mb-4 text-grey font-extrabold uppercase">
        Program lojalnościowy
      </p>
      <h3 class="w-full text-center text-[32px] leading-[32px] mb-12">
        Polecaj IMO
      </h3>
      <div className="lg:w-[100%]">
        <ul className="imo-bullet-list">
          <li>
            <strong>Zaproś</strong> inne biuro lub dewelopera do korzystania z
            IMO.
          </li>
          <li>
            <strong>Otrzymaj zniżkę</strong> na swój abonament za każdego nowego
            klienta, który zamówi IMO z Twojego polecenia!
          </li>
        </ul>
        <p className="text-secondary mt-6">
          <Link
            className="flex items-center justify-center"
            to="/program-lojalnosciowy-polecaj-imo/"
          >
            <IMOButton variant="secondary">Czytaj więcej</IMOButton>
          </Link>
        </p>
      </div>
    </>
  );
};

export default ProgramPromo;
