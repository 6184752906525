import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ArrowRight from "@assets/icons/arrow_right_sm.svg";
import React from "react";

const PortalsPromo = () => {
  return (
    <>
      <p class="w-full text-center text-xs mb-4 text-grey font-extrabold uppercase">
        Rabat dla nowych klientów
      </p>
      <h3 class="w-full text-center text-[32px] leading-[32px] mb-6">
        nieruchomosci-online.pl
      </h3>
      <div className="lg:w-[100%]">
        <StaticImage
          src="../../assets/images/portals/nieruchomosci-online.png"
          alt="Logo nieruchomości online"
          className="lg:!block max-w-[300px] mx-auto mb-6"
        />
        <ul className="imo-bullet-list">
          <li>
            Dwa miesiące bezpłatnego eksportu w ramach dowolnego pakietu
            abonamentowego
          </li>
          <li>
            Pakiet promocyjny zawierający 5 wyróżnień 30-dniowych, do
            wykorzystania w trakcie ww. okresu
          </li>
        </ul>
        <p className="text-secondary mt-6">
          Aby skorzystać z oferty, prosimy wysłać wiadomość o tytule „Promocja
          dla IMO” na adres{" "}
          <a
            href="mailto:support@nieruchomosci-online.pl"
            className="text-blue hover:underline"
          >
            support@nieruchomosci-online.pl
          </a>
        </p>
      </div>
    </>
  );
};

export default PortalsPromo;
