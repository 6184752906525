const strona_www_subpages = [
  {
    label: "Strona ekspresowa",
    to: "/strona-www/strona-ekspresowa/",
    subPages: [
      {
        label: "Strony demonstracyjne",
        to: "/strona-www/strona-ekspresowa#strony-demonstracyjne",
      },
      {
        label: "Funkcje i zalety",
        to: "/strona-www/strona-ekspresowa#funkcje-i-zalety",
      },
      { label: "Cennik", to: "/strona-www/strona-ekspresowa#cennik" },
    ],
  },
  {
    label: "Strona z projektem",
    to: "/strona-www/strona-z-projektem/",
    subPages: [
      {
        label: "Dodatkowe moduły",
        to: "/strona-www/strona-z-projektem/dodatkowe-moduly/",
      },
      { label: "Cennik", to: "/strona-www/strona-z-projektem/konfigurator/" },
    ],
  },
  {
    label: "Strona dedykowana",
    to: "/strona-www/strona-dedykowana/",
  },
  {
    label: "Strona dla dewelopera",
    to: "/strona-www/strona-dla-dewelopera/",
  },
  { label: "Portfolio", to: "/strona-www/portfolio/" },
  { label: "Domena i poczta E-mail", to: "/domena-i-poczta-email/" },
  { label: "Certyfikat SSL", to: "/certyfikat-ssl/" },
  {
    label: "Dodatkowa pomoc techniczna",
    to: "/strona-www/strona-z-projektem/dodatkowa-pomoc-techniczna/",
  },
];
const program_crm_subpages = [
  {
    label: "Funkcje",
    to: "/program-crm/funkcje/praca-online/",
  },
  { label: "Eksport do portali", to: "/program-crm/eksport-do-portali/" },
  { label: "Rabaty w portalach", to: "/program-crm/rabaty-w-portalach/" },
  { label: "Opinie", to: "/program-crm/opinie-o-programie-imo/" },
  { label: "Bezpłatna wersja demo", to: "/program-crm/bezplatna-wersja-demo/" },
  { label: "Zamów IMO", to: "/formularz-zamowienia/" },
];

const pomoc_subpages = [
  {
    label: "IMO",
    to: "/pomoc/imo/konfiguracja-programu/",
    subPages: [
      {
        label: "Konfiguracja programu",
        to: "/pomoc/imo/konfiguracja-programu/",
      },
      {
        label: "Dane agentów i agencji",
        to: "/pomoc/imo/dane-agentow-i-agencji/",
      },
      { label: "Grupy uprawnień", to: "/pomoc/imo/grupy-uprawnien/" },
      {
        label: "Konfiguracja eksportu",
        to: "/pomoc/imo/konfiguracja-eksportu/",
      },
      {
        label: "Konfiguracja statusów",
        to: "/pomoc/imo/konfiguracja-statusow/",
      },
      {
        label: "Zarządzanie lokalizacjami",
        to: "/pomoc/imo/zarzadzanie-lokalizacjami/",
      },
      { label: "Wymagane pola", to: "/pomoc/imo/wymagane-pola/" },
      { label: "Ustawienia listy", to: "/pomoc/imo/ustawienia-listy/" },
      { label: "Eksport oferty", to: "/pomoc/imo/eksport-oferty/" },
      { label: "Wgrywanie bazy ofert", to: "/pomoc/imo/wgrywanie-bazy-ofert/" },
    ],
  },
  {
    label: "Panel WWW",
    to: "/pomoc/panel-www/edycja-banera/",
    subPages: [
      { label: "Edycja banera", to: "/pomoc/panel-www/edycja-banera/" },
      {
        label: "Zarządzanie plikami",
        to: "/pomoc/panel-www/zarzadzanie-plikami/",
      },
      { label: "Edycja treści", to: "/pomoc/panel-www/edycja-tresci/" },
      {
        label: "Zmiana danych dostępowych",
        to: "/pomoc/panel-www/zmiana-danych-dostepowych/",
      },
      {
        label: "Zarządzanie podstronami",
        to: "/pomoc/panel-www/zarzadzanie-podstronami/",
      },
      {
        label: "Oferty specjalne",
        to: "/pomoc/panel-www/oferty-specjalne/",
      },
      {
        label: "Dodawanie tagów",
        to: "/pomoc/panel-www/dodawanie-tagow/",
      },
    ],
  },
  { label: "Poradnik pośrednika", to: "/poradnik-posrednika/" },
  { label: "Baza pośredników", to: "/baza-posrednikow/" },
  { label: "Słownik pojęć", to: "/slownik-pojec/" },
];

export const navItems = [
  {
    label: "Program CRM",
    to: "/program-crm",
    subPages: program_crm_subpages,
    title: "CRM dla biur nieruchomości i deweloperów",
  },
  {
    label: "Strona www",
    to: "/strona-www",
    subPages: strona_www_subpages,
    title: "strony WWW dla deweloperów i biur nieruchomości",
  },
  {
    label: "Cennik",
    to: "/cennik",
    title: "Cennik oprogramowania dla biur nieruchomości IMO",
  },
  { label: "Pomoc", to: "/pomoc", subPages: pomoc_subpages, title: "" },
  { label: "Kontakt", to: "/kontakt", title: "" },
];

const footer_program_crm_sections = [
  { label: "O programie IMO", to: "/program-crm#o-programie-imo" },
  { label: "Opinie pośredników", to: "/program-crm/opinie-o-programie-imo/" },
  { label: "Funkcje", to: "/program-crm/funkcje/praca-online/" },
  { label: "Eksport do portali", to: "/program-crm/eksport-do-portali/" },
  { label: "Korzyści", to: "/program-crm/korzysci/" },
  { label: "Cennik", to: "/program-crm#cennik-crm" },
  { label: "Demo", to: "/program-crm/bezplatna-wersja-demo/" },
  { label: "Zamów", to: "/formularz-zamowienia/" },
];
const footer_strony_www_sections = [
  { label: "Strona ekspresowa", to: "/strona-www/strona-ekspresowa/" },
  { label: "Strona z projektem", to: "/strona-www/strona-z-projektem/" },
  { label: "Strona dedykowana", to: "/strona-www/strona-dedykowana/" },
  { label: "Strona dla dewelopera", to: "/strona-www/strona-dla-dewelopera/" },
  { label: "Portfolio", to: "/strona-www/portfolio/" },
  { label: "Zamów", to: "/formularz-zamowienia/" },
  {
    label: "Poradnik pośrednika",
    to: "/poradnik-posrednika/",
    class: " marginTop-40 font-bold uppercase lg:text-sm",
  },
  {
    label: "Baza pośredników",
    to: "/baza-posrednikow/",
    class: " font-bold uppercase lg:text-sm",
  },
  {
    label: "Słownik pojęć",
    to: "/slownik-pojec/",
    class: " font-bold uppercase lg:text-sm",
  },
];

const footer_strony_www_sections_mobile = [...footer_strony_www_sections];
export const navigation_footer = [
  {
    label: "Program CRM",
    to: "/program-crm/",
    sections: footer_program_crm_sections,
  },
  {
    label: "Strony www",
    to: "/strona-www/",
    sections: footer_strony_www_sections,
  },
];
export const navigation_footer_mobile = [
  {
    label: "Program CRM",
    to: "/program-crm/",
    sections: footer_program_crm_sections,
  },
  {
    label: "Strony www",
    to: "/strona-www/",
    sections: footer_strony_www_sections_mobile,
  },
];
