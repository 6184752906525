import React, { useEffect, useState } from "react";
import IMOButton from "./IMOButton";
import Input from "@components/common/Input";
import { FormProvider, useForm } from "react-hook-form";
import GReCAPTCHA from "@components/common/GReCAPTCHA";
import FormResponse from "@components/common/FormResponse";
import useApiCall, { coupon } from "@utils/useApiCall";

const setCookie = (days = 1) => {
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = "modal=true;" + expires + ";path=/";
};

const Modal = () => {
  const generateCoupon = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const [modal, setModal] = useState(false);
  const [cookieModal, setCookieModal] = useState(true);
  const [validDate, setValidDate] = useState(false);
  const [kupon, setKupon] = useState(null);

  const currentDate = new Date();
  const endDate = new Date("2025-12-01");
  const expirationDate = new Date(
    currentDate.setDate(currentDate.getDate() + 30)
  ).toLocaleDateString();

  const methods = useForm({
    defaultValues: {
      code: generateCoupon(5),
      name: "Kupon rabatowy o wartości 50% na SSL ważny do " + expirationDate,
    },
  });
  const { handleSubmit, reset } = methods;
  const { handleApiCall, isLoading, isSuccess, error } = useApiCall(coupon);

  const onSubmit = ({ recaptcha, ...data }) => {
    setKupon(data.code);
    handleApiCall(data, () => {
      reset();
    });
  };

  useEffect(() => {
    if (endDate > currentDate) {
      setValidDate(true);
    } else {
      setValidDate(false);
    }

    const name = "modal=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    let val;

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        val = c.substring(name.length, c.length);
      }
    }
    setCookieModal(val === "true");

    document.body.addEventListener("mouseleave", function (event) {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        setModal(true);
      }
    });
  }, []);

  const items = [
    {
      title1: "Czy chcesz otrzymać",
      title2: "kupon rabatowy?",
      description1: "Szanujemy Twoją prywatność.",
      description2:
        "Twój numer telefonu nie będzie wykorzystany w celach marketingowych.",
      btnPrimary: "Wygeneruj kupon",
    },
  ];

  return (
    <>
      {!cookieModal && modal && validDate && (
        <>
          <div
            id="popupmodal"
            style={{ maxWidth: "fit-content", height: "fit-content" }}
            className="fixed top-0 left-0 right-0 z-50 p-4 m-auto overflow-x-hidden overflow-y-auto md:inset-0 max-h-full justify-center items-center flex"
          >
            <div className="relative w-full max-w-[800px] max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  type="button"
                  onClick={() => {
                    setModal(false);
                    setCookie(kupon ? 365 : 1);
                    setCookieModal(true);
                  }}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-gray"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 text-center">
                  <section className={`text-center`}>
                    <div className="flex gap-4 flex-wrap">
                      {items.map((i, idx) => (
                        <div key={idx} className="w-full rounded-[32px]">
                          <h3 className="text-[32px] leading-[46px] mb-6">
                            {i.title1}
                            <br />
                            {i.title2}
                            <br />
                          </h3>

                          <FormProvider {...methods}>
                            {!isSuccess && !error && (
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="flex flex-col justify-center mb-2 mx-[50px] gap-4 form-kupon-rabatowy">
                                  <Input
                                    label="Wpisz numer telefonu"
                                    name="phone"
                                    type="text"
                                    mask="tel"
                                    inputMode="numeric"
                                    required
                                  />
                                  <GReCAPTCHA />
                                </div>
                                <p className="text-secondary mb-8">
                                  {i.description1}
                                  <br />
                                  {i.description2}
                                  <br />
                                </p>
                                <div className="flex justify-center gap-4">
                                  {i.btnPrimary && (
                                    <IMOButton
                                      loading={isLoading}
                                      type="submit"
                                      variant="primary"
                                    >
                                      {i.btnPrimary}
                                    </IMOButton>
                                  )}
                                </div>
                              </form>
                            )}
                            <FormResponse
                              isSuccess={isSuccess}
                              error={error}
                              onCloseResponse={() => {
                                if (isSuccess) {
                                  setModal(false);
                                  setCookie(365);
                                  setCookieModal(true);
                                }
                              }}
                              customSuccessMessage={
                                <span
                                  className="span_success"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      "Twój kupon rabatowy ważny przez 30 dni to <b>" +
                                      kupon +
                                      "</b><br>50% rabatu na SSL przez <b>pierwszy rok</b> dla nowych klientów.",
                                  }}
                                />
                              }
                            />
                          </FormProvider>
                        </div>
                      ))}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ background: "black", opacity: "0.7" }}
            aria-hidden="true"
            className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
            onClick={() => {
              setModal(false);
              setCookie(kupon ? 365 : 1);
              setCookieModal(true);
            }}
          ></div>
        </>
      )}
    </>
  );
};

export default Modal;
