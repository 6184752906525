import React from "react";
import PropTypes from "prop-types";
import "./IMOButton.css";

const IMOButton = (props) => {
  const {
    tag: Tag,
    className = "",
    innerRef,
    onClick,
    disabled,
    variant,
    loading,
    ...attributes
  } = props;

  const click = (e) => !disabled && onClick && onClick(e);

  const classes = `btn ${className} ${
    variant === "primary"
      ? "btn-imo-primary"
      : variant === "secondary"
      ? "btn-imo-secondary"
      : "btn-imo-secondary-white"
  }`;
  return (
    <Tag
      className={classes}
      type="button"
      disabled={disabled || loading}
      {...attributes}
      onClick={click}
      ref={innerRef}
    >
      {attributes.children}
      {loading && <div className="button-spinner"></div>}
    </Tag>
  );
};

IMOButton.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  variant: PropTypes.oneOf(["", "primary", "secondary", "secondary-white"]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

IMOButton.defaultProps = {
  tag: "button",
};

export default IMOButton;
