import { useState } from "react";

// Zamówienie RWD z modułami
export const orderRwd = "/order/rwd";
// Zamówienie IMO
export const orderImo = "/order/imo";
// Zamówienie darmowego IMO
export const orderImoFree = "/order/imo-free";
// Zamówienie strony WWW
export const orderWWW = "/order/www";
// Zamówienie darmowej strony WWW
export const orderWWWFree = "/order/www-free";
// Zamówienie IMO mobile
export const orderMobile = "/order/mobile";
// Zamówienie poczty
export const orderMailbox = "/order/mailbox";
// Zamówienie ssl-a
export const orderSsl = "/order/ssl";
// Dodanie opinii
export const opinion = "/order/opinion";
// Kupon rabatowy
export const coupon = "/order/coupon";

const email_error_type = "value_error.email";

const useApiCall = (url, setFormError = () => null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);
  const handleApiCall = (data, onSuccess = () => null) => {
    if (typeof window === "undefined") return;
    const baseUrl =
      (window._env_ && window._env_.API_URL) ||
      process.env.GATSBY_API_URL ||
      window.location.origin;
    const endpoint = `${baseUrl}${url}`;
    // nullowanie pustych wartości
    const fixedData = Object.entries(data).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: v || typeof v === "boolean" ? v : null,
      }),
      {}
    );

    setIsLoading(true);
    if (error) setError(null);
    if (isSuccess) setIsSuccess(false);
    fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(fixedData),
    })
      .then((response) => {
        if (response.ok) {
          setIsSuccess(true);
          setError(null);
          onSuccess();
        } else {
          response.json().then((error) => {
            if (error?.detail?.[0]?.type === email_error_type) {
              setFormError("email", {
                type: "custom",
                message: "Wpisz poprawny adres E-mail",
              });
              setError({
                customMessage: "Podany adres E-mail nie jest poprawny.",
              });
            } else setError(true);
          });
        }
      })
      .catch((error) => {
        setIsSuccess(false);
        setError(error || true);
      })
      .finally(() => setIsLoading(false));
  };

  return { handleApiCall, isLoading, isSuccess, error };
};
export default useApiCall;
