import React from "react";
import Collapse from "@components/common/CollapseFaq";
import { faqCRM } from "@utils/faqData";

const Faq = ({ id }) => {
  return (
    <section id={id} className="text-center pb-10">
      <div className="mt-8 mb-8 lg:px-[104px] space-y-4">
        <h3 id="additional-modules" className="text-start">
          Najczęściej zadawane pytania
        </h3>
        {faqCRM.map((i, idx) => (
          <Collapse key={idx} title={i.title}>
            {i.description}
          </Collapse>
        ))}
      </div>
    </section>
  );
};

export default Faq;
