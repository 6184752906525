import React, { useEffect, useState } from "react";
import SuccessIcon from "@assets/icons/sukces.svg";
import ErrorIcon from "@assets/icons/blad.svg";
import CloseIcon from "@assets/icons/close.svg";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

const FormResponse = ({
  isSuccess,
  error,
  onCloseResponse,
  customSuccessMessage,
  conversion,
}) => {
  const {
    formState: { submitCount, errors },
  } = useFormContext();
  const [formError, setFormError] = useState(false);
  const [closed, setClosed] = useState(false);

  const closeHandle = () => {
    setClosed(true);
    setFormError(false);
    onCloseResponse && onCloseResponse();
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setFormError(true);
    }
  }, [submitCount, errors]);

  useEffect(() => {
    setClosed(false);
    setFormError(false);
    if (isSuccess && conversion) {
      window.gtag("event", "conversion", {
        send_to: conversion,
      });
    }
  }, [isSuccess, error, conversion]);

  if (formError)
    return (
      <div
        className={`w-full flex my-3 p-6 rounded-lg justify-between items-start text-white transition-all bg-red`}
      >
        <div className="inline-block mr-2">
          <ErrorIcon className="inline-block align-top w-6 h-6 min-w-[24px]" />
          <p className="ml-2 inline items-center relative top-[2px] text-base">
            <strong>Błąd!</strong> Nie uzupełniono wymaganych pól.
          </p>
        </div>
        <button
          className="cursor-pointer hover:fill-grey opacity-100 hover:opacity-70 transition-all"
          onClick={closeHandle}
        >
          <CloseIcon className="w-6 h-6 min-w-[24px] fill-[inherit] child:fill-[inherit]" />
        </button>
      </div>
    );

  if (closed) return null;
  return isSuccess || Boolean(error) ? (
    <div
      className={`w-full flex my-3 p-6 rounded-lg justify-between items-start text-white transition-all ${
        isSuccess ? "bg-[#3ecf80]" : "bg-red"
      }`}
    >
      <div className="inline-block mr-2">
        {isSuccess ? (
          <SuccessIcon className="inline-block align-top w-6 h-6 min-w-[24px]" />
        ) : (
          <ErrorIcon className="inline-block align-top w-6 h-6 min-w-[24px]" />
        )}
        <p className="ml-2 inline items-center relative top-[2px] text-base">
          <strong>{isSuccess ? "Sukces!" : "Błąd!"}</strong>{" "}
          {isSuccess
            ? customSuccessMessage ||
              "Formularz został poprawnie wysłany. Skontaktujemy się z Tobą w ciągu 24 godzin."
            : error.customMessage || "Coś poszło nie tak. Spróbuj później."}
        </p>
      </div>
      <button
        className="cursor-pointer hover:fill-grey opacity-100 hover:opacity-70 transition-all"
        onClick={closeHandle}
      >
        <CloseIcon className="w-6 h-6 min-w-[24px] fill-[inherit] child:fill-[inherit]" />
      </button>
    </div>
  ) : null;
};

FormResponse.propTypes = {
  isSuccess: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onCloseResponse: PropTypes.func,
  customSuccessMessage: PropTypes.string,
  conversion: PropTypes.string,
};

export default FormResponse;
