import React from 'react'
import './input.css'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import { useMask } from '@react-input/mask'

const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

const validateEmail = (email) => {
    return Boolean(emailRegex.test(email.toLowerCase()))
}

const Input = ({
    type = 'text',
    label,
    name,
    required,
    invalid,
    defaultValue = '',
    customValidation,
    containerClassName = '',
    labelClassName = '',
    mask = '',
    ...rest
}) => {
    const {
        control,
        formState: { errors },
        setError,
        clearErrors,
        watch,
    } = useFormContext()
    const isInvalid = invalid || Boolean(errors[name])
    const isEmail = name === 'email' || type === 'email'
    const inputTel = useMask({
        mask: '+48 ___ ___ ___',
        replacement: { _: /\d/ },
    })
    return (
        <div className={`form-control ${isInvalid ? 'is-invalid' : ''} ${containerClassName}`}>
            <label htmlFor={name} className={labelClassName}>
                {label}
                {required && <span className="text-red">*</span>}
            </label>
            <Controller
                name={name}
                render={({ field: { value, onChange, onBlur } }) => {
                    const changeHandle = (e) => {
                        if (customValidation && !customValidation(e)) return
                        onChange(e)
                        const val = e.target.value
                        if (isEmail && (!val || (isInvalid && validateEmail(val)))) clearErrors(name)
                    }
                    const blurHandle = (e) => {
                        onBlur(e)
                        const val = e.target.value
                        if (isEmail && val.length > 0 && !validateEmail(val)) setError(name)
                    }
                    const fixValue = value || ''
                    return (
                        <input
                            name={name}
                            id={name}
                            type={type}
                            value={fixValue}
                            onChange={changeHandle}
                            onBlur={blurHandle}
                            ref={mask === 'tel' ? inputTel : null}
                            {...rest}
                        />
                    )
                }}
                control={control}
                defaultValue={defaultValue}
                rules={{ required, ...(isEmail ? { validate: validateEmail } : {}) }}
            />
            {isEmail && isInvalid && watch(name)?.length > 0 && (
                <p className="text-red text-sm">Wpisz poprawny adres E-mail</p>
            )}
            {errors.emailCode && watch('emailCode')?.length > 0 && (
                <p className="text-red text-sm">Nieprawidłowy kod.</p>
            )}
            {errors['emailCode']?.type === 'required' ? <p className="text-red text-sm">Wprowadź liczby.</p> : ''}
        </div>
    )
}

Input.propTypes = {
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    containerClassName: PropTypes.string,
    required: PropTypes.bool,
    invalid: PropTypes.bool,
    customValidation: PropTypes.func,
    labelClassName: PropTypes.string,
    mask: PropTypes.string,
}

export default Input
