import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'

const style = `
  .recaptcha-invalid iframe {
    border: 1px solid var(--color-red);
      border-radius: 4px;
  }
  .recaptcha iframe {
    border: 1px solid transparent;  
  }
`

const GReCAPTCHA = ({ name = 'recaptcha' }) => {
  const {
    register,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext()
  const invalid = Boolean(errors[name])
  register(name, { required: true })

  const resetCaptcha = () => {
    setValue(name, null)
  }

  return (
    <div className={invalid ? 'recaptcha-invalid' : 'recaptcha'}>
      <ReCAPTCHA
        sitekey="6Le0LA4UAAAAAJOfkBnF9i0IIoE_nF7-iqksU3R2"
        onChange={(value) => {
          setValue(name, value)
          clearErrors(name)
        }}
        onExpired={resetCaptcha}
        onErrored={resetCaptcha}
        hl="pl"
      />
      <style>{style}</style>
    </div>
  )
}

GReCAPTCHA.propTypes = {
  name: PropTypes.string,
}

export default GReCAPTCHA
