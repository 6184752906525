import { Link } from 'gatsby'
import React from 'react'
import IMOButton from './IMOButton'

const BirthdayPromotion = ({ className = '' }) => {
    return (
        <section className={`text-center ${className}`}>
            <div className="flex gap-4 flex-wrap">
                <div className="w-full bg-soft-grey py-10 px-6 lg:py-[86px] lg:px-[208px] rounded-[32px]">
                    <p className="text-xs text-grey mb-2 font-extrabold uppercase">Promocja</p>
                    <h3 className="text-[32px] leading-[46px] mb-8">
                        17 urodziny IMO!
                        <br />
                        IMO oraz WWW przez 2 miesiące gratis
                    </h3>
                    <p className="text-secondary mb-8">
                        Zamawiając IMO i WWW w pakiecie zyskujesz łączny rabat na obie usługi wynoszący 22% i dodatkowo
                        w promocji 2 miesiące gratis!
                    </p>
                    <div className="flex justify-center gap-4">
                        <a target="_blank" href="/regulaminy/Regulamin-17-urodziny-IMO.pdf">
                            <IMOButton variant="secondary">Regulamin promocji</IMOButton>
                        </a>
                        <Link to="/formularz-zamowienia">
                            <IMOButton variant="primary">Zamów</IMOButton>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BirthdayPromotion
