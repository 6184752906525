import React, { Fragment, useCallback, useEffect, useState } from "react";
import Logo from "@assets/images/logo.svg";
import Eclipse from "@assets/images/navbar-eclipse.svg";
import Arrow from "@assets/images/arrow-top.svg";
import { Link, navigate } from "gatsby";
import "./navbar.css";
import { navItems } from "./utils/NavbarData";
import Facebook from "@images/footer/facebook.svg";

const navItem_hover =
  "after:content-[' '] after:absolute after:-bottom-1 after:left-0 after:h-1 after:bg-white hover:after:w-full after:rounded-lg";
const navItem_mobile_hover =
  "after:content-[' '] after:absolute after:-bottom-1 after:left-0 after:h-[3px] after:bg-blue hover:after:w-full after:rounded-lg";

const disabledPaths = {
  "strona-www/strona-z-projektem": [
    "strona-www/strona-z-projektem/portfolio",
    "strona-www/strona-z-projektem/dodatkowa-pomoc-techniczna",
  ],
};
const customPaths = {
  "strona-www": ["domena-i-poczta-email", "certyfikat-ssl"],
  "strona-www/strona-ekspresowa#cennik": [
    "strona-www/strona-ekspresowa#cennik",
  ],
  "strona-www/strona-ekspresowa#funkcje-i-zalety": [
    "strona-www/strona-ekspresowa#funkcje-i-zalety",
  ],
  "strona-www/strona-ekspresowa#strony-demonstracyjne": [
    "strona-www/strona-ekspresowa#strony-demonstracyjne",
  ],
  "strona-www/strona-ekspresowa": [
    "strona-www/strona-ekspresowa#cennik",
    "strona-www/strona-ekspresowa#funkcje-i-zalety",
    "strona-www/strona-ekspresowa#strony-demonstracyjne",
  ],
};
// odpowiada za ustawianie aktywnych class dla linków które nie są na tym samym url
// albo są na tym samym url ale w menu nie są na tym samym poziomie
// jeżeli nie jest zablokowane albo customowo włączone w {disabledPaths} albo {customPaths}
// włączy się domyślnie aktywna z gatsbiego
const validateActiveClass = (href, currentPath) => {
  const hrefClean = href.replace(/^\/|\/$/g, "");
  const currentPathClean = currentPath.replace(/^\/|\/$/g, "");
  if (customPaths[hrefClean]?.includes(currentPathClean)) return true;
  else if (disabledPaths[hrefClean]?.includes(currentPathClean)) return false;
  return null;
};

const Navbar = ({ home, path }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [collapsed, setCollapsed] = useState([]);
  const [scrollModeActive, setScrollModeActive] = useState(false);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    setScrollModeActive(currentScrollPos > 0);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleScroll();
      window.addEventListener("scroll", handleScroll);
    }
    return () =>
      typeof window !== "undefined" &&
      window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const arrowClickHandle = (name) => {
    if (collapsed.includes(name))
      setCollapsed((prev) => prev.filter((i) => i !== name));
    else setCollapsed((prev) => [...prev, name]);
  };
  const linkClickHandle = (e, i = {}) => {
    if (mobileNavOpen) {
      document.body.style.overflowY = "auto";
      setMobileNavOpen(false);
    }
    navigate(i.customLink);
  };
  const toggleMobileMenu = () => {
    document.body.style.overflowY = mobileNavOpen ? "auto" : "hidden";
    setMobileNavOpen((prev) => !prev);
  };

  const renderSubItems = (items) => {
    return items.map((item, index) =>
      item.label === "Pomoc" ? (
        <div className="group cursor-pointer hidden lg:block">
          <Link
            partiallyActive={true}
            onClick={linkClickHandle}
            key={index}
            title={item.title}
            to={item.to}
            className={`menu-hover text-sm leading-6 font-extrabold text-white relative after:w-0 after:transition-all after:ease-in-out after:duration-300 ${navItem_hover}`}
            activeClassName={
              "after:content-[' '] after:absolute after:-bottom-1 after:left-0 after:h-1 after:bg-white after:!w-full after:rounded-lg"
            }
          >
            {item.label}
          </Link>
          <div className="invisible absolute z-50 group-hover:visible">
            <div className="mt-4 flex flex-col bg-white py-4 px-4 shadow-xl rounded-lg">
              {item.subPages.map((subpage, index) => (
                <Link
                  to={subpage.to}
                  key={index}
                  className={`py-1 text-sm md:mx-2 text-blue font-normal hover:underline`}
                >
                  {subpage.label}
                </Link>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="hidden lg:block">
          <Link
            partiallyActive={true}
            onClick={linkClickHandle}
            key={index}
            title={item.title}
            to={item.to}
            className={`text-sm leading-6 font-extrabold text-white relative after:w-0 after:transition-all after:ease-in-out after:duration-300 ${navItem_hover}`}
            activeClassName={
              "after:content-[' '] after:absolute after:-bottom-1 after:left-0 after:h-1 after:bg-white after:!w-full after:rounded-lg"
            }
          >
            {item.label}
          </Link>
        </div>
      )
    );
  };

  const renderMobileItems = (items, nestedLevel = false) => {
    return items.map((item) => {
      const hasSubPages = item.subPages?.length > 0;
      const isExtended = collapsed.includes(item.to);
      const validateActive = validateActiveClass(item.to, path);

      // ustawianie class dla linków w mobilnym menu
      let activeClassName =
        "after:content-[' '] after:absolute after:-bottom-1 after:left-0 after:h-[3px] after:bg-blue after:!w-full after:rounded-lg";
      let mainClassName = `${hasSubPages ? "with-arrow" : ""}${
        nestedLevel ? "" : " font-bold"
      } text-[0.91rem] leading-6 text-blue relative w-fit after:w-0 after:transition-all after:ease-in-out after:duration-300 ${navItem_mobile_hover}`;
      if (validateActive)
        mainClassName =
          mainClassName +
          " after:content-[' '] after:absolute after:-bottom-1 after:left-0 after:h-[3px] after:bg-blue after:!w-full after:rounded-lg";
      else if (validateActive === false) activeClassName = "";

      return (
        <Fragment key={item.to}>
          <div
            className={`flex items-center mb-6 page-item-container ${
              hasSubPages ? "with-arrow" : ""
            }`}
          >
            {hasSubPages && (
              <button
                onClick={() => arrowClickHandle(item.to)}
                className={
                  "mr-1 z-20 cursor-pointer p-2 -ml-2 arrow-btn " +
                  (isExtended ? "arrow-top" : "arrow-bottom")
                }
              >
                <Arrow />
              </button>
            )}
            <Link
              partiallyActive={true}
              onClick={(e) => linkClickHandle(e, item)}
              key={item.to}
              to={item.to}
              className={mainClassName}
              activeClassName={activeClassName}
            >
              {item.label}
            </Link>
          </div>
          {hasSubPages && (
            <div
              className={`sub-pages-container ${
                isExtended ? "max-h-full" : "max-h-0"
              } sub-pages-level-${item.to.match(/\//g).length - 1}`}
            >
              {renderMobileItems(item.subPages, true)}
            </div>
          )}
        </Fragment>
      );
    });
  };
  return (
    <>
      <header
        className={`sticky top-0 z-20 navbar-container ${
          scrollModeActive && !mobileNavOpen
            ? "navbar-scrolled"
            : mobileNavOpen
            ? "bg-white"
            : ""
        }`}
      >
        <div className="container mx-auto px-4">
          <nav className="pt-4 lg:pt-8 flex justify-between items-center font-light px-2 lg:px-0">
            <Link
              onClick={() => mobileNavOpen && linkClickHandle()}
              to="/"
              className="flex items-center nav-logo"
            >
              <Logo className="w-24 lg:w-auto" />
              <p className="text-blue-secondary mt-4 ml-3 lg:hidden xl:block text-xs lg:text-base font-light">
                DLA NIERUCHOMOŚCI
              </p>
            </Link>
            <div className="flex items-center gap-14">
              {renderSubItems(navItems)}
              <button
                aria-label="Toggle navigation"
                type="button"
                className={`navbar-toggler mt-2 ${
                  mobileNavOpen ? "open" : ""
                } block lg:hidden`}
                onClick={toggleMobileMenu}
              >
                <span />
                <span />
                <span />
              </button>
            </div>
          </nav>
        </div>
      </header>

      <div
        className={`mobile-menu p-8 pb-0 lg:hidden ${
          mobileNavOpen ? "open" : ""
        }`}
      >
        <div className="flex flex-col justify-between">
          <div>{renderMobileItems(navItems)}</div>

          <div className="pb-8">
            <div className="xl:px-24 text-grey flex items-center">
              <p className="text-sm">
                Copyright © {new Date().getFullYear()} IMO
              </p>
              <Link
                to="/polityka-prywatnosci"
                className="text-sm ml-9 p-2"
                onClick={linkClickHandle}
              >
                Polityka prywatności
              </Link>
            </div>
            <div className="w-full mt-4">
              <a
                href="https://www.facebook.com/people/IMO-program-dla-biur-nieruchomo%C5%9Bci/100054310490010/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <Facebook className="child:fill-black" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-screen overflow-hidden absolute top-0 left-0">
        <Eclipse
          className={`eclipse-top hidden lg:block ${
            home ? "eclipse-home" : "eclipse-pages"
          }`}
          alt="Tło"
        />
      </div>
    </>
  );
};

export default Navbar;
